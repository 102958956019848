<template>
  <v-container fluid>
    <v-row class="mt-2 px-12" v-if="dataLoading">
      <v-col cols="12">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i + 12">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" class="mt-8">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" lg="4" v-for="i in 12" :key="i">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-form @submit.prevent ref="form">
        <v-card-title class="primary--text">
          Edit Vehicle
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-card flat outlined class="pa-4 pa-sm-6">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Vehicle Information
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Vehicle Number *"
                  v-model="vehicle.vehicle_no"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  flat
                  v-model="vehicle.driver_uuid"
                  :items="drivers"
                  item-text="name"
                  item-value="uuid"
                  label="Driver"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  flat
                  v-model="vehicle.category"
                  :rules="[rules.required]"
                  :items="categories"
                  label="Category *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Engine Number *"
                  v-model="vehicle.engine_no"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Chassis Number *"
                  v-model="vehicle.chassis_no"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Model *"
                  v-model="vehicle.model"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  flat
                  outlined
                  label="Make *"
                  v-model="vehicle.make"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-autocomplete
                  flat
                  v-model="vehicle.class"
                  :rules="[rules.required]"
                  :items="classes"
                  label="Class *"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Registration Date *"
                      v-model="vehicle.registration_date"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vehicle.registration_date"
                    color="primary"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>

          <v-card flat outlined class="pa-4 pa-sm-6 mt-8">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Oil Information
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Last Oil Changed Date *"
                      v-model="vehicle.oil_changed_date"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vehicle.oil_changed_date"
                    color="primary"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  type="number"
                  flat
                  outlined
                  label="Last Oil Changed Meter (km) *"
                  v-model="vehicle.oil_changed_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  type="number"
                  flat
                  outlined
                  label="Oil Change Interval Meter (km) *"
                  v-model="vehicle.oil_change_interval_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  type="number"
                  flat
                  outlined
                  label="Oil Change Notification Meter (km) *"
                  v-model="vehicle.oil_change_notification_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <v-card flat outlined class="pa-4 pa-sm-6 mt-8">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Mechanical Service Information
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu6"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Last Mechanical Service Date *"
                      v-model="vehicle.mechanical_serviced_date"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vehicle.mechanical_serviced_date"
                    color="primary"
                    no-title
                    @input="menu6 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  type="number"
                  flat
                  outlined
                  label="Last Mechanical Service Meter (km) *"
                  v-model="vehicle.mechanical_serviced_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  type="number"
                  flat
                  outlined
                  label="Mechanical Service Interval Meter (km) *"
                  v-model="vehicle.mechanical_service_interval_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-text-field
                  type="number"
                  flat
                  outlined
                  label="Mechanical Service Notification Meter (km) *"
                  v-model="vehicle.mechanical_service_notification_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <v-card flat outlined class="pa-4 pa-sm-6 mt-8">
            <v-sheet class="mb-5 text-h6" color="transparent">
              Important Dates
            </v-sheet>
            <v-row class="mt-2">
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu3"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Revenue License Date *"
                      v-model="vehicle.revenue_license_date"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vehicle.revenue_license_date"
                    color="primary"
                    no-title
                    @input="menu3 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu4"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Insurance Date *"
                      v-model="vehicle.insurance_date"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vehicle.insurance_date"
                    color="primary"
                    no-title
                    @input="menu4 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu5"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Emission Test Date *"
                      v-model="vehicle.emission_test_date"
                      :rules="[rules.required]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vehicle.emission_test_date"
                    color="primary"
                    no-title
                    @input="menu5 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
            cancel
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="editVehicle"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-title>
      </v-form>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";

import {
  getVehicle,
  editVehicle,
  syncOfflineVehiclesChanges,
} from "@/services/vehiclesService";

import constants from "@/constants.json";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    categories: constants.vehicleCategories,
    classes: constants.vehicleClasses,
    vehicle: {
      uuid: "",
      vehicle_no: "",
      engine_no: "",
      chassis_no: "",
      category: "",
      class: "",
      model: "",
      make: "",
      registration_date: "",
      mechanical_serviced_date: "",
      mechanical_serviced_km: 0,
      mechanical_service_notification_km: 0,
      mechanical_service_interval_km: 0,
      oil_changed_date: "",
      oil_changed_km: 0,
      oil_change_interval_km: 0,
      oil_change_notification_km: 0,
      driver_uuid: "",
      revenue_license_date: "",
      insurance_date: "",
      emission_test_date: "",
      staff: {},
    },
    drivers: [],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle",
        "Update"
      ) ||
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle",
        "Manage Personal"
      )
    ) {
      this.dataLoading = true;
      await syncOfflineVehiclesChanges();
      this.drivers = await fetchAndStoreStaffData();
      this.drivers.forEach((driver) => {
        driver["name"] = driver.first_name + " " + driver.last_name;
      });
      this.vehicle = await getVehicle(this.$route.params.uuid);
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.viewItem(this.vehicle);
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewVehicle",
        params: { uuid: item.uuid },
        query: { bc: item.vehicle_no },
      });
    },
    async editVehicle() {
      if (this.$refs.form.validate()) {
        this.vehicle.staff = this.drivers.find(
          (supervisor) => supervisor.uuid == this.vehicle.driver_uuid
        );
        this.loading = true;
        let editVehicleReturn = await editVehicle(this.vehicle);
        this.loading = false;
        if (editVehicleReturn == "success") {
          this.viewItem(this.vehicle);
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },
  },
  updated() {
    //
  },
};
</script>